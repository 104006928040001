$(document).ready(function() {

  if ($('#datatables').length > 0) {
    $('#datatables').DataTable({
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "TODOS"]
      ],
      language: {
        "sEmptyTable": "Nenhum registro encontrado",
        "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
        "sInfoFiltered": "(Filtrados de _MAX_ registros)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ resultados por página",
        "sLoadingRecords": "Carregando...",
        "sProcessing": "Processando...",
        "sZeroRecords": "Nenhum registro encontrado",
        "sSearch": "Pesquisar",
        "oPaginate": {
          "sNext": "Próximo",
          "sPrevious": "Anterior",
          "sFirst": "Primeiro",
          "sLast": "Último"
        },
        "oAria": {
          "sSortAscending": ": Ordenar colunas de forma ascendente",
          "sSortDescending": ": Ordenar colunas de forma descendente"
        }
      }
    });

    var table = $('#datatables').DataTable();

    // Delete a record
    table.on('click', '.remove', function(e) {
      $tr = $(this).closest('tr');
      table.row($tr).remove().draw();
      e.preventDefault();
    });
  }

  if ($('#formLogin').length > 0) {
    $('#formLogin').validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 5
        }
      },
      messages: {
        email: {
          required: 'Por favor, digite seu e-mail.',
          email: 'Por favor, digite um e-mail válido.'
        },
        password: {
          required: 'Por favor, digite sua senha.',
          minlength: 'Por favor, digite uma com no minímo 5 caracteres.'
        }
      }
    });
  }

  if ($('#formRecover').length > 0) {
    $('#formRecover').validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        email: {
          required: 'Por favor, digite seu e-mail.',
          email: 'Por favor, digite um e-mail válido.'
        }
      }
    });
  }

  if ($('#formRegistroCliente').length > 0) {
    $('#formRegistroCliente').validate({
      rules: {
        nome: {
          required: true,
          minlength: 5
        },
        email: {
          required: true,
          email: true
        },
        cpf: {
          required: true,
          cpf: true
        },
        password: {
          required: true,
          minlength: 5
        }
      },
      messages: {
        nome: {
          required: 'Por favor, digite seu nome.',
          minlength: 'Por favor, digite um nome com no minímo 5 caracteres.'
        },
        email: {
          required: 'Por favor, digite seu e-mail.',
          email: 'Por favor, digite um e-mail válido.'
        },
        cpf: {
          required: 'Por favor, digite seu CPF.',
          cpf: 'Por favor, digite um CPF válido.'
        },
        password: {
          required: 'Por favor, digite sua senha.',
          minlength: 'Por favor, digite uma senha com no minímo 5 caracteres.'
        }
      },
      submitHandler: function(form) {
        $(form).find('button[type=submit]').prop('disabled', true);
        return true;
      }
    });
  }

  if ($('#formularioCadastrarAnalises').length > 0) {
    $('#formularioCadastrarAnalises').validate({
	    rules: {
	      titulo: {
	      	required: true
	      },
	      exame: {
		      required: true,
		      extension: "pdf"
		    },
        cliente: {
          required: true
        },
        descricao: {
          required: true
        }
      },
      messages: {
	      titulo: {
	      	required: 'Por favor, digite o título do laudo.'
	      },
	      exame: {
		      required: 'Por favor, selecione o arquivo de laudo.'
		    },
        cliente: {
          required: 'Por favor, selecionar o engenheiro que deve receber o laudo.'
        },
        descricao: {
          required: 'Por favor, digite o cliente favorecido.'
        }
	    }
	  });
  }

  if ($('.form-users-register').length > 0) {
    $('.form-users-register').validate({
      rules: {
        "nome-completo": {
          required: true,
          minlength: 5
        },
        cpf: {
          required: true,
          cpf: true
        },
        email: {
          required: true,
          email: true
        },
        senha: {
          required: true,
          minlength: 5
        },
        "confirmar-senha": {
          required: true,
          minlength: 5,
          equalTo: "#registerPassword"
        },
        "nivel-acesso": {
          required: true
        }
      },
      messages: {
        "nome-completo": {
          required: 'Por favor, digite o nome do usuário.',
          minlength: 'Por favor, digite um nome com no minímo 5 caracteres.'
        },
        cpf: {
          required: 'Por favor, digite seu CPF.',
          cpf: 'Por favor, digite um CPF válido.'
        },
        email: {
          required: 'Por favor, digite seu e-mail.',
          email: 'Por favor, digite um e-mail válido.'
        },
        senha: {
          required: 'Por favor, digite sua senha.',
          minlength: 'Por favor, digite uma senha com no minímo 5 caracteres.'
        },
        "confirmar-senha": {
          required: 'Por favor, digite sua confirmação de senha.',
          minlength: 'Por favor, digite uma senha com no minímo 5 caracteres.',
          equalTo: 'A senha digitada não é igual a anterior.'
        },
        "nivel-acesso": {
          required: 'Por favor, selecione o nível de acesso do usuário.'
        }
      }
    });
  }
  
  if ($.fn.mask.length > 0) {
    if ($(".mask-cpf").length > 0) {
      $(".mask-cpf").mask("999.999.999-99");
    }

    if ($(".mask-telefone").length > 0) {
      $(".mask-telefone").mask("(99) 9999?9-9999");
    }
  }

});